import './App.css';
import {Component} from "react";
import web3 from './web3';
import lottery from "./contracts/lottery";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      manager: '',
      players: [],
      balance: '',
      value: '0.011',
      message: '',
  };
  }

  async componentDidMount() {
    const manager = await lottery.methods.manager().call();
    let players = await lottery.methods.getPlayers().call();
    let balance = await web3.eth.getBalance(lottery.options.address);

    this.setState({ manager, players, balance });

    lottery.events.playerEntered(async function(error, result) {
      window.location.reload();
    });

  }

  onSubmit = async (event) => {
    event.preventDefault();

    const accounts = await web3.eth.getAccounts();

    this.setState({message : 'İşlem yapılıyor. Lütfen Bekleyin....'});

    await lottery.methods.enter().send({
      from: accounts[0],
      value: web3.utils.toWei(this.state.value,'ether')
    });

    this.setState({message: 'Katılımınız gerçekleşti. Bol Şanslar.'});
  }

  onClick = async () => {
    const accounts = await web3.eth.getAccounts();

    this.setState({message: 'Kazananı seçiyorum. Lütfen bekleyin...'});

    await lottery.methods.pickWinner().send({
      from: accounts[0]
    });

    this.setState({message: 'Kazanan seçildi!' });
  }


  render() {
    return (
       <div>
           <h2>Kazan Kazan Sözleşmesi</h2>
         <p>Sözleşme adresi: <a target={"new"} href={"https://kovan.etherscan.io/address/" + lottery.options.address}><b>{lottery.options.address}</b></a></p>
           <p>Bu sözleşme <a target={"new"} href={"https://kovan.etherscan.io/address/" + this.state.manager}><b>{this.state.manager}</b></a> adresi tarafından yönetilmektedir.</p>
           <p>Şuan <b>{this.state.players.length}</b> oyuncu oyuna katıldı.</p>
           <p>Toplam ödül miktarı <b>{web3.utils.fromWei(this.state.balance,'ether')} ether!</b></p>

         <hr/>

           <form onSubmit={this.onSubmit}>
             <h4>Şansını denemek ister misin?</h4>
             <div>
               <label>Girebileceğin ether miktarı (0.011) : </label>
               <input value={this.state.value} onChange={event => this.setState({value: event.target.value})} />
             </div>
             <button>Yarışmaya Katıl!</button>
          </form>

         <hr/>

         <h4>Kazananı Seçmeye Hazır mısın?</h4>
         <button onClick={this.onClick}>Kazananı Seç!</button>

         <hr/>

         <h3>{this.state.message}</h3>

        </div>
    )
  }
}
export default App;
